import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Scorpio.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Scorpio Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/scorpio"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Scorpio</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Scorpio Traits</h4>
        
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Scorpio Traits </h1>
                <h2 className="text-sm md:text-base ml-4">Oct 24 - Nov 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-love");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-nature");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-man");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-traits");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-man");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-traits");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-facts");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            The zodiac sign Scorpio is the most intense in the zodiac. They are known for their passion, assertiveness, determination, and decisiveness.<br/><br/>
The planet of transformation and regeneration, Scorpio is the only sign in the zodiac that is not associated with either the element of air or water. The zodiac constellation name in which this sign is located is "Scorpius."<br/><br/>
The zodiac sign of the Scorpion is the most intense in the zodiac. They are known for their passion, assertiveness, determination, and decisiveness. The planet of transformation and regeneration, the Scorpion, is a symbol of power, survival, and rebirth.<br/><br/>

Scorpio is also considered the most sensual sign on the zodiac chart.
 <br/><br/>
Born natural leaders, Scorpios are always searching for truth no matter what the situation is. They are honest to a fault and will not sugarcoat anything. This trait makes them trustworthy and reliable but can also get them into trouble. They are hot-headed and passionate and will not think twice before speaking their mind.
Scorpios are natural leaders. They are often perceived to be strong-willed and passionate about what they do. They are usually born with a strong passion for knowledge and truth, making them great leaders. On the other hand, this same trait can also make them stubborn and hard to deal with.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Let's look at some of the positive and negative traits of Scorpio</p><br/>
<p className="font-bold text-orange-500 inline-block mr-1">Positive Traits:</p>
Attentive, Brave, Loyal, Reliable, and Ambitious<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-1">Negative Traits:</p>
Jealous, Possessive, Secret, Dominating, Resentful
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Scorpio Positive Traits</p><br/>
<p className="playfair text-black text-xl md:text-3xl">Focused</p><br/>
Scorpio individuals are focused and competitive. When they want something, they just go for it. Also, when they set their sight on something, they allow very few things to get in their way and achieve. They have a robust set of beliefs and values and are not easily dissuaded once they set their minds to something.
 
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl ">Brave</p><br/>
Scorpios will do anything to obtain their goals. They are natural risk-takers and tend to have a lot of energy. They are highly ambitious, competitive, and constantly pushing their boundaries and taking on challenges.
Scorpios are brave and daring, so what appears to be a crazy risk to more conservative signs is a typical day for the brave-hearted Scorpio.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Loyal</p><br/>
Scorpios value trust and honesty, making them a fiercely loyal sign, and they expect their partners to be the same way. Scorpios are faithful to their families, friends, and their partners.
The Scorpio is a loyal partner, and in return, the Scorpio expects the same commitment from their significant other.
 
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Faithful</p><br/>
Scorpios are passionate and intense. They have an extreme sense of duty and responsibility, which sometimes means they're not the most affectionate or romantic of signs. They're an excellent choice for partners who are just as committed because they'll stick by your side until the end. A Scorpio loved is faithful, strong, protective, gentle, loving, and devoted to the end.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Ambitious</p><br/>
The Scorpios are ambitious and competitive. They have a strong desire to achieve greatness and are incredibly tenacious in pursuing goals. They often have great difficulty admitting failure, and their emotions can be easily hurt. They have a deep well of affection and loyalty, and they seek out a special someone who can complement their intensity.
 <br/><br/>
Scorpio Negative Traits
<p className="playfair text-black text-xl md:text-3xl">Jealous</p><br/>
There is no joke about how jealous Scorpio people can be. For Scorpios, jealousy is the one in the first place, together with revenge. If you do something terrible to a Scorpio, they will pay.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Possessive</p><br/>
In relationships, Scorpios become quickly enraged if the partner pays too much attention to anyone they deem a threat. They are a bit more possessive in the case of their loved ones and near ones. 
They like to know who is around and who is not. This is not to say they don't want to be surrounded by loved ones, but they are particularly possessive of their closest ones.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Secretive</p><br/>
Scorpios are the mysterious and secretive sign of the zodiac. Scorpios hardly ever reveal something about themselves to people to protect their feelings. Due to their secret character, it can be challenging for others to know what they're thinking or feeling. 
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Dominating</p><br/>
Scorpios happen to be the most dominant control freaks. Scorpios always dislike being controlled by others. Scorpios happen to be one of the most prevalent and influential zodiac signs. They are the most stubborn and stubborn of all the signs. They can't change their behavior.
 <br/><br/>
 
 
<p className="playfair text-black text-xl md:text-3xl">Resentful</p><br/>
Suppose you do something to a Scorpio that they perceive as a betrayal. There's a good chance that your actions will cause some big-time resentment toward them.<br/><br/>
It's a shame that Scorpions are so easily angered, but they can be tough to deal with. The best thing to do is to be as polite as possible. Understanding the characteristics of a Scorpio will help you build better relationships.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Scorpio Love And Relationships</p><br/>
A mysterious Scorpio in love could be a fully invested and influential partner. The power of the Scorpio is that it can extract a soul from another person. 
However, they are also very demanding and possess a rigid sense of pride. They are highly protective of their partner and have the ability to turn their back on them at the drop of a hat. Scorpios in love are not good people, not if you want the best of both worlds.
<br/><br/>
Scorpios in a relationship are very sacrificing, proving their devotion, depth, and loyalty. 
<br/><br/>
 Scorpios are highly protective, so their partners may not be the best person to share these emotions. However, a scorpion will not turn off its partner, and will fight with its partner to protect them. I can imagine that Scorpio Scorpions want their partner to share their feelings, but they often do not want to be hurt. I think they may be afraid that they will lose them if they are left alone.
Read more about Scorpio relationships.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Scorpio - Friends And Family</p><br/>
Scorpions are very loyal. They will live up to their family obligations and be happy to answer your questions, but do not expect any special treatment.
Scorpions live in a very protective and protective family. If you are close to a Scorpio, you will feel strongly protected and cared for. Scorpions will most likely be very protective of their family, especially if you are in their proximity.

<br/><br/>
Scales and Scorpions are among the most loyal and trustworthy of the animal kingdom. They are known for their loyal and trustworthy nature and are known as trustworthy creatures. Scorpions are known as the most trustworthy of the animal kingdom. They are known for their loyalty to their friends and their loyalty is in and of itself a form of ethical behavior.
<br/><br/>
Scorpio can be a friend for life and can make you think deeper, laugh harder, and have more fun than you ever felt before.
<br/><br/>
Every friendship is unique and different. But according to astrology, some sun signs play better with others. So who are your best and worst friend matches? click here to Read More…

<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Scorpio Career And Money</p><br/>
Scorpios tend to be competitive, which can serve them well at work, where they pursue success with determination. Scorpio’s concentration level and resolution are superb, which guarantees the successful completion of their goals every time.
<br/><br/>
Read more about scorpion career.
 <br/><br/>

 <p className="playfair text-black text-xl md:text-3xl">Scorpio and Money</p><br/>
Scorpios believe in everything in life with an intense focus and purpose — including money. They are brave and powerful with their financial decisions.They never have to worry about money or what they might need in the future. They are always looking for the opportunity to get rich quickly. They will only buy something they really need. They always have a secret stash of money somewhere.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Scorpio?</p><br/>
Scorpios are very interesting, mysterious, and irresistibly attractive. Use the following to attract Scorpio if you want to lure them and want them to be in your life.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">You stand your ground</p>
So, you need to find out what they like and what they don't like. The best way to do this is to watch them. You need to find out what they want and don't like, and then you need to see how you can put it in your advertisements. They will love you if you stand your ground.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Don't Tell Lies</p>
If you are lying, the Scorpio will usually tell you the truth, but with a bit of effort, they can be swayed to say you the truth as well.
A lying Scorpio will ignore you and try to get you to take the easy way out. 
 <br/><br/>
Now you know more about the Scorpio personality traits, find out how you match with other Zodiac Signs.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl ">Compatible Signs Scorpio Should Consider: </p>
Scorpios have an emotionally intense nature and demand a deep and powerful connection with their love interest. The most compatible with Scorpios are generally considered to be Cancer, Capricorn, and Pisces.


<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
